import ReactDOM from "react-dom";
import { ExtensionUtil } from "../utils/utils";
import Axios from "axios";
import React from "react";
import ProductExplorerGUI from "./components/ProductExplorerGUI";
import { Client } from "../../../clientKim";

export default class ProductExplorerExtensionPanel extends Autodesk.Viewing.UI
  .DockingPanel {
  //=================================================================================================================
  protected _initialXPos = "400";
  protected _initialYPos = "140";
  protected _initialWidth = "360";
  protected _initialHeight = "500";
  private _DOMContent: HTMLDivElement;
  private _reactNode: any;
  private _wrapper: any = null;
  private _scrollContainer;
  private _viewer = null;
  private _axiosInstance = null;
  private _axiosBaseUrl = null;
  private _apiClient: Client = null;
  private _guiPanel: ProductExplorerGUI = null;
  public _currentModelURN: string = null;
  //=================================================================================================================

  constructor(viewer: any, container: any, id: any, title: any, options: any) {
    super(viewer.container, id, title, options);

    Autodesk.Viewing.UI.DockingPanel.call(this, container, id, title, options);

    this._viewer = viewer;

    if (ExtensionUtil.isContentNotNull(options.init_options.MODEL_URN)) {
      this._currentModelURN = options.init_options.MODEL_URN;
    }

    if (ExtensionUtil.isContentNotNull(options.init_options.BASE_URL)) {
      this._axiosBaseUrl = options.init_options.BASE_URL;
    }

    if (
      ExtensionUtil.isContentNotNull(options.init_options.PANEL_INITIAL_POS)
    ) {
      const posString = options.init_options.PANEL_INITIAL_POS;
      const xyCoords = posString.split(",");
      if (xyCoords.length === 4) {
        this._initialXPos = xyCoords[0];
        this._initialYPos = xyCoords[1];
        this._initialWidth = xyCoords[2];
        this._initialHeight = xyCoords[3];
      }
    }

    this.container.classList.add("docking-panel-container-solid-color-a"); // white => docking.panel
    this.container.style.width = "750px";
    this.container.style.backgroundColor = "#fff";
    this.container.style.resize = "both";
    this.container.style.overflow = "hidden";
    this.container.style.left = "50px";
    this.container.style.top = this._initialYPos + "px";

    //=================================================================================
    this.container.classList.add(".modelhirar-react-docking-panel");
    this._DOMContent = document.createElement("div");
    // this._DOMContent.setAttribute("id", "modelGroupPanel");
    // this._DOMContent.className = "content";
    this._DOMContent.className = "productExplorer-panel";
    this._DOMContent.classList.add("hiddenScrollbar");
    //=================================================================================
    // this._wrapper = document.createElement("div");

    // this._wrapper
    //   .setAttribute
    // "class",
    // "TEST" //"productExplorer-panel" //modelgroupsContainer flex-column d-flex p-0 m-0"
    // (); // p-2 m-5");
    // this._wrapper.appendChild(this._DOMContent);
    //=================================================================================
    this.container.appendChild(this._DOMContent);

    if (this._axiosInstance == null) {
      this._axiosInstance = Axios.create({ baseURL: this._axiosBaseUrl });

      if (this._axiosInstance != null) {
        this._apiClient = new Client(this._axiosBaseUrl, this._axiosInstance);
      } else {
        alert("axios error");
      }
    }
  }

  //===================================================================================================================

  public initialize(): void {
    this.title = this.createTitleBar(this.titleLabel || this.container.id);
    this.container.appendChild(this.title);

    this.initializeMoveHandlers(this.title);

    this.closer = this.createCloseButton();
    this.container.appendChild(this.closer);

    this.footer = this.createFooter();
    this.container.appendChild(this.footer);
  }

  //=================================================================================================================

  async setVisible(show) {
    super.setVisible(show);

    if (show) {
      //==============================================================================
      const allCultures: string[] = await this._apiClient.getCultures();

      this._reactNode = ReactDOM.render(
        //TODO fix this any
        <ProductExplorerGUI
          parentPanel={this}
          axiosApiClient={this._apiClient}
          cultures={allCultures}
          viewer={this._viewer}
          currentModel={this._currentModelURN}
        /> as any,
        this._DOMContent
      );
      //==============================================================================
    } else if (this._reactNode) {
      ReactDOM.unmountComponentAtNode(this._DOMContent);
      this._reactNode = null;
    }
  }

  //=================================================================================================================

  public getApiClient() {
    return this._apiClient;
  }
  public getViewer() {
    return this._viewer;
  }
  public setGUIPanel(guiPanel: ProductExplorerGUI) {
    this._guiPanel = guiPanel;
  }
  public setModelURN(modelURN: string): void {
    this._currentModelURN = modelURN;
  }
  public getModelURN(): string {
    return this._currentModelURN;
  }

  //=================================================================================================================

  public enlargePanel(nColumns: number): void {
    const initialWidth = parseInt(this._initialWidth, 10);
    const newExtraWidth = nColumns * 110;
    const newWidth = initialWidth + newExtraWidth;
    this.container.style.width = newWidth.toString() + "px";
  }

  //=================================================================================================================
}
