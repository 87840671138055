import './css/DefaultExtension.css';
import { ExtensionUtil } from '../utils/utils'
import ReactDOM from 'react-dom';
import DefaultGUIPanel from "./components/DefaultGUIPanel";
import React from 'react';

export class DefaultExtensionPanel extends Autodesk.Viewing.UI.DockingPanel {
    //=================================================================================================================
    protected _initialXPos = "80";
    protected _initialYPos = "140";
    protected _initialWidth = "500";
    protected _initialHeight = "400";
    protected _buttonInit: HTMLButtonElement;
    protected _buttonExport: HTMLButtonElement;
    private _DOMContent: HTMLDivElement;
    private _reactNode: any;
    private _guiPanel: DefaultGUIPanel = null;
    public _wrapper: HTMLDivElement;
    //=================================================================================================================


    constructor(viewer: any, container: any, id: any, title: any, options: any) {
        super(viewer.container, id, title, options);

        Autodesk.Viewing.UI.DockingPanel.call(this, container, id, title, options);

        if (ExtensionUtil.isContentNotNull(options.init_options.PANEL_INITIAL_POS)) {
            const posString = options.init_options.PANEL_INITIAL_POS;
            const xyCoords = posString.split(',');
            if (xyCoords.length === 4) {
                this._initialXPos = xyCoords[0];
                this._initialYPos = xyCoords[1];
                this._initialWidth = xyCoords[2];
                this._initialHeight = xyCoords[3];
            }
        }

        this.container.classList.add("docking-panel-container-solid-color-a");
        this.container.style.width = "440px"; //this._initialWidth +"px";  
        this.container.style.height = "440px"; //this._initialHeight +"px"; 

        //  this.container.style.width       = "auto";
        //  this.container.style.height      = "auto";
        this.container.style.left = this._initialXPos + "px";
        this.container.style.top = this._initialYPos + "px";

        //=================================================================================

        //        this._buttonInit         = document.createElement("button");
        //        this._buttonExport       = document.createElement("button");

        this.container.classList.add('react-uni-docking-panel')
        //=================================================================================
        this._DOMContent = document.createElement('div')
        this._DOMContent.setAttribute("id", "default-dp");
        this._DOMContent.className = 'content'
        //=================================================================================
        this._wrapper = document.createElement('div');
        this._wrapper.setAttribute("class", "defaultcontainer flex-column d-flex p-0 m-0");
        this._wrapper.appendChild(this._DOMContent);
        //=================================================================================
        this.container.appendChild(this._wrapper);
        //=================================================================================



        // const div_container = document.createElement("div");
        // div_container.setAttribute("class",  "container-fluid");


        //     const div_row = document.createElement("div");
        //     div_row.setAttribute("class",  "row");

        //         const div_col = document.createElement("div");
        //         div_col.setAttribute("class",  "col-6");

        //             this._buttonInit.setAttribute("class", "btn btn-danger dbutton");
        //             this._buttonInit.innerHTML = "Init database";
        //            // this._buttonInit.style.margin = "15px 0px 25px 0px";  // top right bottom left

        //         const div_col_2 = document.createElement("div");
        //         div_col_2.setAttribute("class",  "col-6");

        //             this._buttonExport.setAttribute("class", "btn btn-danger dbutton");
        //             this._buttonExport.innerHTML    = "Export to CSV";
        //           // this._buttonExport.style.margin = "15px 15px 25px 0px";  // top right bottom left


        //     div_col.appendChild(    this._buttonInit );
        //     div_col_2.appendChild(  this._buttonExport );

        //     div_row.appendChild( div_col );
        //     div_row.appendChild( div_col_2 );



        //     div_container.appendChild(  div_row );

        //     this.container.appendChild( div_container );


    }

    //===================================================================================================================

    public initialize(): void {

        this.title = this.createTitleBar(this.titleLabel || this.container.id);
        this.container.appendChild(this.title);

        this.initializeMoveHandlers(this.container);

        this.closer = this.createCloseButton();
        this.container.appendChild(this.closer);

        this.footer = this.createFooter();
        this.container.appendChild(this.footer);
    }

    //=================================================================================================================

    public setGUIPanel(guiPanel: DefaultGUIPanel) {
        this._guiPanel = guiPanel;
    }

    //=================================================================================================================

    setVisible(show) {
        if (this._guiPanel) {
            //this._guiPanel.close();
        }


        super.setVisible(show)

        if (show) {
            //TODO fix this any
            this._reactNode = ReactDOM.render(<DefaultGUIPanel parentPanel={this} /> as any, this._DOMContent)
        }
        else if (this._reactNode) {
            ReactDOM.unmountComponentAtNode(this._DOMContent)
            this._reactNode = null
        }
    }

    //=================================================================================================================
}



