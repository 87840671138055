import React from "react";
import "./ForgeViewer.css";
import Script from "react-load-script";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "./Loading";

import { SearchExtension } from "./extensions/SearchExtension/SearchExtension";
import { ScreenShotExtension } from "./extensions/ScreenShotExtension/ScreenShotExtension";
import { ChartExtension } from "./extensions/ChartExtension/ChartExtension";
import { DbInitExtension } from "./extensions/DbInitExtension/DbInitExtension";
import { AssemblyExtension } from "./extensions/AssemblyExtension/AssemblyExtension";
import { DefaultExtension } from "./extensions/DefaultExtension/DefaultExtension";
import { PropertyExtension } from "./extensions/PropertyExtension/PropertyExtension";
import { PlayerExtension } from "./extensions/PlayerExtension/PlayerExtension";
import { ModelLoaderExtension } from "./extensions/ModelLoaderExtension/ModelLoaderExtension";
import { UnifiExtension } from "./extensions/UnifiExtension/UnifiExtension";

import { TestExtension } from "./extensions/TestExtension/TestExtension";
import { ShelfMakerExtension } from "./extensions/ShelfMakerExtension/ShelfMakerExtension";
import { BIM360DataManagerExtension } from "./extensions/BIM360DataManagerExtension/BIM360DataManagerExtension";
import { ItemNumberCrawlerExtension } from "./extensions/ItemNumberCrawlerExtension/ItemNumberCrawlerExtension";
import { ModelConfiguratorExtension } from "./extensions/ModelConfiguratorExtension/ModelConfiguratorExtension";
import { ViewerSettingsExtension } from "./extensions/ViewerSettingsExtension/ViewerSettingsExtension";
import { BimCadExtension } from "./extensions/BimCadExtension/BimCadExtension";
import { PlacementExtension } from "./extensions/PlacementExtension/PlacementExtension";

import { ProductExplorerExtension } from "./extensions/ProductExplorereExtension/ProductExplorerExtension";

import Config from "../Config";

const viewerLibaryURL = "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/viewer3D.min.js";
//const viewerStylesheetURL = 'https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/style.min.css';

// const options : Autodesk.Viewing.InitializerOptions = {
//   env: "Local"
// }

const ForgeViewer: React.FC = () => {
	let container: HTMLElement | null;
	let viewer: Autodesk.Viewing.GuiViewer3D;

	const handleScriptLoad = () => {
		container = document.getElementById("forgeviewer");

		if (container !== null) {
			viewer = new Autodesk.Viewing.GuiViewer3D(container);
		}

		var options = {
			env: "Local",
			// accessToken: '<YOUR_APPLICATION_TOKEN>',

			document: Config.ModelPath,

			// only for tests-to get the model-paths !!!!

			//   './models/shaver/0.svf'
			//   './models/firestop/11784192-4viewerrvt/Resource/3D View/{3D} 231951/{3D}.svf'
			//   './models/small-revit-sample-house/Resource/3D_View/_3D_/_3D_.svf'
			//   "./models/Seagreen_2020.09.21/0/0.svf",
			//   "./models/xxx/BASF/9123_J534_FHB_2019_limited.rvt/Resource/3D View/{3D}.sfv",
			//   ""
			//"document":   "./models/BASF-NEW/9123_J534_FHB_E2_2022.rvt/Resource/3D View/Tracking 32706181/Tracking.svf"
			//"document":   "./models/Condos/Condos/cc051d36-a0ca-dc86-a20d-6244e66ab539/0.svf"
		};

		Autodesk.Viewing.Initializer(options, function () {

			//Autodesk.Viewing.theExtensionManager.registerExtension("Hilti.DbInitExtension", DbInitExtension);
			Autodesk.Viewing.theExtensionManager.registerExtension("Hilti.AssemblyExtension", AssemblyExtension);
			if (Config.IsItAHDA === "ahda") {
				Autodesk.Viewing.theExtensionManager.registerExtension("Hilti.UnifiExtension", UnifiExtension);
				Autodesk.Viewing.theExtensionManager.registerExtension("Hilti.PlayerExtension", PlayerExtension);
				Autodesk.Viewing.theExtensionManager.registerExtension("Hilti.SearchExtension", SearchExtension);
				Autodesk.Viewing.theExtensionManager.registerExtension("Hilti.ScreenshotExtension", ScreenShotExtension);
				Autodesk.Viewing.theExtensionManager.registerExtension("Hilti.ChartExtension", ChartExtension);
				Autodesk.Viewing.theExtensionManager.registerExtension("Hilti.DefaultExtension", DefaultExtension);
				Autodesk.Viewing.theExtensionManager.registerExtension("Hilti.ModelLoaderExtension", ModelLoaderExtension);
				Autodesk.Viewing.theExtensionManager.registerExtension("Hilti.PropertyExtension", PropertyExtension);
				Autodesk.Viewing.theExtensionManager.registerExtension("Hilti.BIM360DataManagerExtension", BIM360DataManagerExtension);
				Autodesk.Viewing.theExtensionManager.registerExtension("Hilti.ItemNumberCrawlerExtension", ItemNumberCrawlerExtension);
				Autodesk.Viewing.theExtensionManager.registerExtension("Hilti.ModelConfiguratorExtension", ModelConfiguratorExtension);
				Autodesk.Viewing.theExtensionManager.registerExtension("Hilti.ViewerSettingsExtension", ViewerSettingsExtension);
				Autodesk.Viewing.theExtensionManager.registerExtension("Hilti.BimCadExtension", BimCadExtension);

				Autodesk.Viewing.theExtensionManager.registerExtension("Test.TestExtension", TestExtension);
				Autodesk.Viewing.theExtensionManager.registerExtension("Test.ShelfMakerExtension", ShelfMakerExtension);

				Autodesk.Viewing.theExtensionManager.registerExtension("Hilti.ProductExplorerExtension", ProductExplorerExtension);
			}

			loadViewer(options);
		});
	};

	const loadViewer = (options: any) => {
		Autodesk.Viewing.Initializer(options, function () {
			loadExtensions();
			viewer.start(options.document);
			addEvents();

			let modelNameIsURN = false;

			if (Config.ModelPath != null && Config.ModelPath.length > 0) {
				const fileName = Config.ModelPath.toLowerCase();
				const checkedModelName = fileName.trim(); // remove blank, LF, CR from start and end of the given string
				if (checkedModelName.endsWith(".svf") == false) {
					modelNameIsURN = true;
				}
			}
			if (modelNameIsURN) {
				// we dont have local svf file , load the model from the web by urn
				loadModelByURN(Config.ModelPath.trim());
				//setGroundShadow();
			}
		});
	};

	// Add here your needed Autodesk Events
	const addEvents = () => {
		Autodesk.Viewing.theExtensionManager.unregisterExtension('Autodesk.Explode');
	};

	// Add your extensions here (scripts)
	return <Script url={viewerLibaryURL} onLoad={handleScriptLoad}></Script>;

	function loadModelByURN(modelDocId: string) {
		setTimeout(function () {
			ModelLoaderExtension.loadModelByURN(viewer, modelDocId, Config.BaseUrl);
		}, 50);
	}

	function setGroundShadow() {
		setTimeout(function () {
			viewer.setGroundShadow(false);
		}, 2500);
	}

	function loadExtensions() {
		//loadDbInitExtension();
		loadAssemblyExtension();

		if (Config.IsItAHDA === "ahda") {
			loadUnifiExtension();
			loadPlayerExtension();
			loadSearchExtension();
			loadScreenshotExtension();
			loadChartExtension();
			loadModelLoaderExtension();
			loadPropertyExtension();
			loadBIM360DataManagerExtension();
			//loadItemNumberCrawlerExtension();
			loadModelConfiguratorExtension();
			loadViewerSettingsExtension();
			loadBimCadExtension();
			loadVisualClusterExtension();
			loadProductExplorerExtension();
			loadPlacementExtension();
			viewer.loadExtension("Autodesk.TransformControls");
		}
		//  loadDefaultExtension();
		//  loadLEAExtension();

		//  loadTestExtension();
		//  loadLEAModelLoaderExtension();
		//  loadShelfMakerExtension();
	}

	//===================================================================================================================

	function loadSearchExtension() {
		SearchExtension.loadExtension(viewer);
	}

	//===================================================================================================================

	function loadScreenshotExtension() {
		ScreenShotExtension.loadExtension(viewer);
	}

	//===================================================================================================================

	function loadChartExtension() {
		ChartExtension.loadExtension(viewer);
	}

	//===================================================================================================================

	function loadDbInitExtension() {
		DbInitExtension.loadExtension(viewer);
	}

	//===================================================================================================================

	function loadAssemblyExtension() {
		AssemblyExtension.loadExtension(viewer);
	}

	//===================================================================================================================

	function loadDefaultExtension() {
		DefaultExtension.loadExtension(viewer);
	}

	//===================================================================================================================

	//===================================================================================================================

	function loadBIM360DataManagerExtension() {
		BIM360DataManagerExtension.loadExtension(viewer);
	}

	//===================================================================================================================

	function loadItemNumberCrawlerExtension() {
		ItemNumberCrawlerExtension.loadExtension(viewer);
	}

	//===================================================================================================================

	function loadPropertyExtension() {
		PropertyExtension.loadExtension(viewer);
	}

	//===================================================================================================================

	function loadVisualClusterExtension() {
		viewer.loadExtension("Autodesk.VisualClusters", {});
	}

	//===================================================================================================================

	function loadPlayerExtension() {
		PlayerExtension.loadExtension(viewer);
	}

	//===================================================================================================================

	function loadTestExtension() {
		TestExtension.loadExtension(viewer);
	}

	function loadLEAModelLoaderExtension() {
		const init_options = {
			PANEL_INITIAL_POS: "20,90,400,614", // initial x1Pos,y1Pos,Width,Height of the panel
			WRITE_LOG_FILE: 1, // 1 => write to console.log  .., 0 => dont write
		};
		viewer.loadExtension("Test.LEAModelLoaderExtension", { init_options });
	}

	//===================================================================================================================

	function loadShelfMakerExtension() {
		const init_options = {
			PANEL_INITIAL_POS: "20,90,400,614", // initial x1Pos,y1Pos,Width,Height of the panel
			WRITE_LOG_FILE: 1, // 1 => write to console.log  .., 0 => dont write
		};

		viewer.loadExtension("Test.ShelfMakerExtension", { init_options });
	}

	//===================================================================================================================

	function loadModelLoaderExtension() {
		ModelLoaderExtension.loadExtension(viewer, Config.ModelPath);
	}

	//===================================================================================================================

	function loadProductExplorerExtension() {
		ProductExplorerExtension.loadExtension(viewer, Config.ModelPath);
	}

	//===================================================================================================================

	function loadPlacementExtension() {
		Autodesk.Viewing.theExtensionManager.registerExtension('PlacementExtension', PlacementExtension);
		viewer.loadExtension('PlacementExtension');
	}

	//===================================================================================================================

	function loadModelConfiguratorExtension() {
		ModelConfiguratorExtension.loadExtension(viewer);
	}

	//===================================================================================================================

	function loadUnifiExtension() {
		UnifiExtension.loadExtension(viewer);
	}

	//===================================================================================================================

	function loadViewerSettingsExtension() {
		ViewerSettingsExtension.loadExtension(viewer);
	}

	//===================================================================================================================

	function loadBimCadExtension() {
		BimCadExtension.loadExtension(viewer);
	}

	//===================================================================================================================
};

export default withAuthenticationRequired(ForgeViewer, {
	onRedirecting: () => <Loading />,
});
