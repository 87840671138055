import "../utils/GuiComponents/base.css";
import './css/AssemblyExtension.css';
import { ExtensionUtil } from '../utils/utils'
import AssemblyExtensionBusinessLogic from './AssemblyExtensionBusinessLogic';
import { CircleSpinner } from '../utils/GuiComponents/CircleSpinner';
import ReactDOM from 'react-dom';
import React from 'react';

export default class AssemblyExtensionPanel extends Autodesk.Viewing.UI.DockingPanel {
    protected _spinnerWrapper: HTMLDivElement;
    protected _qrCodeWrapper: HTMLDivElement;
    protected _barChartWrapper: HTMLDivElement;
    protected _reactNode: any;
    protected _button: HTMLButtonElement;
    protected _assembliesInfoDiv: HTMLDivElement;
    protected _errInfoDiv: HTMLDivElement;
    protected _assembliesTableDiv: HTMLDivElement;
    protected _projectIdentDiv: HTMLDivElement;
    protected _scrollcontainer;
    protected _businessLogic: AssemblyExtensionBusinessLogic;
    protected _assemblyIdent: HTMLLabelElement
    protected _barChartCanvas: HTMLCanvasElement;
    protected _DOMContent;
    protected _viewer = null;
    protected _panelWrapper: HTMLDivElement;
    protected _initialXPos = "80";
    protected _initialYPos = "140";
    protected _initialWidth = "500";
    protected _initialHeight = "400";
    protected _spinnerSize: number = 30;
    protected _spinnerColor: string = "#FF0000";
    //=================================================================================================================

    constructor(viewer: any, container: any, id: any, title: any, options: any) {

        super(viewer.container, id, title, options);

        Autodesk.Viewing.UI.DockingPanel.call(this, container, id, title, options);

        this._viewer = viewer;

        //========================================================================

        this._businessLogic = new AssemblyExtensionBusinessLogic(this, viewer, options);

        //========================================================================

        if (ExtensionUtil.isValidContent(options)) {
            if (ExtensionUtil.isContentNotNull(options.init_options.SPINNER_SIZE)) {
                this._spinnerSize = options.init_options.SPINNER_SIZE;
            }
            if (ExtensionUtil.isContentNotNull(options.init_options.SPINNER_COLOR)) {
                this._spinnerColor = options.init_options.SPINNER_COLOR;
            }
            if (ExtensionUtil.isContentNotNull(options.init_options.PANEL_INITIAL_POS)) {
                const posString = options.init_options.PANEL_INITIAL_POS;
                const xyCoords = posString.split(',');
                if (xyCoords.length === 4) {
                    this._initialXPos = xyCoords[0];
                    this._initialYPos = xyCoords[1];
                    this._initialWidth = xyCoords[2];
                    this._initialHeight = xyCoords[3];
                }
            }
        }

        //========================================================================

        this.container.classList.add("docking-panel-container-solid-color-a");
        this.container.style.width = this._initialWidth + "px";
        this.container.style.height = this._initialHeight + "px";
        this.container.style.minWidth = "200px";
        this.container.style.minHeight = "50px";
        this.container.style.resize = "none";
        this.container.style.position = "absolute";
        this.container.style.left = this._initialXPos + "px";
        this.container.style.top = this._initialYPos + "px";

        //========================================================================

        this._panelWrapper = document.createElement("div");
        this._panelWrapper.setAttribute("class", "assemblyPanelWrapper);  // flex-column d-flex p-2");

        //===============================================================

        this._barChartWrapper = document.createElement("div");
        this._barChartWrapper.setAttribute("class", "barChartWrapper flex-column d-flex p-0");
        this._barChartWrapper.style.margin = "5px 10px 5px 10px";

        this._barChartCanvas = document.createElement("canvas");
        this._barChartCanvas.setAttribute("id", "barChartCanvas");
        this._barChartCanvas.style.margin = "0px";


        this._barChartWrapper.appendChild(this._barChartCanvas);

        //===================================================================

        this._button = document.createElement("button");
        this._button.setAttribute("class", "btn btn-secondary");
        this._button.innerHTML = "Reload data";
        this._button.style.margin = "10px 15px 10px 15px";
        this._button.onclick = (e) => { this.onClickLoadData(e); }

        //===========================================================================

        this._projectIdentDiv = document.createElement("div");
        this._projectIdentDiv.setAttribute("id", "projectIdentification-assembly");
        this._projectIdentDiv.style.margin = "10px 15px 10px 15px";
        this._projectIdentDiv.innerHTML = "";

        //=======================================================================

        this._assembliesInfoDiv = document.createElement("div");
        this._assembliesInfoDiv.setAttribute("id", "assembliesInfoArea");
        this._assembliesInfoDiv.style.margin = "10px 15px 10px 15px";  // top-right-bottom-left
        this._assembliesInfoDiv.innerHTML = "";

        //===========================================================================

        this._assembliesTableDiv = document.createElement("div");
        this._assembliesTableDiv.setAttribute("id", "assembliesTable");
        this._assembliesTableDiv.style.margin = "10px 10px 10px 10px";    // top right bottom left
        this._assembliesTableDiv.innerHTML = "";

        //===============================================================

        this._assemblyIdent = document.createElement("label");
        this._assemblyIdent.setAttribute("id", "assemblyIdentification");
        this._assemblyIdent.setAttribute("class", "d-flex  justify-content-center");
        this._assemblyIdent.innerHTML = "";
        this._assemblyIdent.style.margin = "10px 15px 10px 15px";
        this._assemblyIdent.setAttribute("height", "20");

        //===============================================================

        this._qrCodeWrapper = document.createElement("div");
        this._qrCodeWrapper.setAttribute("class", "d-flex justify-content-center");
        this._qrCodeWrapper.innerHTML = "";

        const qrCodeCanvas = document.createElement("canvas");
        qrCodeCanvas.setAttribute("id", "qrCode");
        qrCodeCanvas.setAttribute("class", "d-flex p-0 m-0 justify-content-center");
        qrCodeCanvas.innerHTML = "";

        this._qrCodeWrapper.appendChild(qrCodeCanvas);

        //========================================================
        this.container.classList.add('react-docking-panel')
        this._DOMContent = document.createElement('div')
        this._DOMContent.className = 'content'

        this._spinnerWrapper = document.createElement('div');
        this._spinnerWrapper.setAttribute("class", "spinnerContainer-assembly flex-column d-flex"); // p-2 m-5");
        this._spinnerWrapper.appendChild(this._DOMContent);

        //========================================================

        this._errInfoDiv = document.createElement("div");
        this._errInfoDiv.setAttribute("id", "stateInfoArea-assembly");
        this._errInfoDiv.style.margin = "10px 15px 10px 15px";
        this._errInfoDiv.innerHTML = "";

        //=======================================================================
        // append all elements to the scrollcontainer
        this._panelWrapper.appendChild(this._projectIdentDiv);
        this._panelWrapper.appendChild(this._assembliesInfoDiv);
        this._panelWrapper.appendChild(this._button);
        this._panelWrapper.appendChild(this._errInfoDiv);
        this._panelWrapper.appendChild(this._assembliesTableDiv);  // Tabulator-Grid
        this._panelWrapper.appendChild(this._barChartWrapper);     // Chart
        this._panelWrapper.appendChild(this._assemblyIdent);
        this._panelWrapper.appendChild(this._qrCodeWrapper);
        this._panelWrapper.appendChild(this._spinnerWrapper);        // Spinner

        //=========================================================================
        // append the scrollcontainer to the panel
        this.container.appendChild(this._panelWrapper);
        //=========================================================================

        this._DOMContent.style.display = "none";             // hide spinner


        //=========================================================================

    }

    //=================================================================================================================

    public initialize(): void {

        // const op = { left: false, heightAdjustment: 45, marginTop: 0 };
        // const scrollContainer = this.createScrollContainer(op);

        this.title = this.createTitleBar(this.titleLabel || this.container.id);
        this.container.appendChild(this.title);

        this.initializeMoveHandlers(this.container);

        this.closer = this.createCloseButton();
        this.container.appendChild(this.closer);

        this.footer = this.createFooter();
        this.container.appendChild(this.footer);


    }

    //=================================================================================================================

    private onClickLoadData(e: any) {
        this.clearGUI();
        this._businessLogic.processLoadData(true, this._barChartCanvas);
        this._businessLogic.loadAweaElements();
    }

    //=================================================================================================================

    public onNotifyStartLoadData() {
        this.showGUIElements(false);
        this.showButton(false);
        this.showSpinner(true);
    }

    //=================================================================================================================

    public onNotifyEndLoadData(errInfo: string, buttonText: string) {
        this.showSpinner(false);
        this.showButton(true);

        if (!errInfo) {
            this.showGUIElements(true);  // 
        }
        else {
            this.setErrorInfo(errInfo); // if there is an error, dont show GUI-Elemets ( chart,Table ...) again
        }

        if (buttonText)
            this._button.innerHTML = buttonText
    }

    //=================================================================================================================

    public setErrorInfo(errInfo: string) {
        if (this._errInfoDiv.style.display === 'none' || this._errInfoDiv.style.display === '')
            this._errInfoDiv.style.display = "block";
        this._errInfoDiv.innerHTML = errInfo;
    }

    //=================================================================================================================

    public setProjectInfo(projectIdentInfo: string, assembliesInfo: string) {
        this._projectIdentDiv.innerHTML = projectIdentInfo;
        this._assembliesInfoDiv.innerHTML = assembliesInfo;
    }

    //=================================================================================================================

    private showSpinner(bShow: boolean) {
        if (bShow) {
            if (this._DOMContent.style.display === 'none')
                this._DOMContent.style.display = "block";           // show spinner
            //this._panelWrapper.appendChild(this._DOMContent);    
        }
        else {
            if (this._DOMContent.style.display === 'block')
                this._DOMContent.style.display = "none";             // hide spinner
            //this._panelWrapper.removeChild(this._DOMContent);      
        }
    }

    public showButton(bShow: boolean) {
        if (bShow) {
            if (this._button.style.display === 'none')
                this._button.style.display = "block";           // show button
        }
        else {
            // if (this._button.style.display === 'block')
            this._button.style.display = "none";             // hide button
        }
    }

    //=================================================================================================================

    private clearGUI(): void {
        this._projectIdentDiv.innerHTML = "";
        this._assembliesInfoDiv.innerHTML = "";
        this._assembliesTableDiv.innerHTML = "";
        this._assemblyIdent.innerHTML = "";
        this._errInfoDiv.innerHTML = "";

        const canvasDiv = document.getElementById("qrCode");
        const canvasElement: HTMLCanvasElement = (canvasDiv as HTMLCanvasElement);
        const ctx = canvasElement.getContext("2d");
        ctx.clearRect(0, 0, canvasElement.width, canvasElement.height);

        const ctx2 = this._barChartCanvas.getContext("2d");
        ctx2.clearRect(0, 0, this._barChartCanvas.width, this._barChartCanvas.height);
    }

    //=================================================================================================================
    public setVisible(show): void {
        super.setVisible(show)

        if (show) {

            this._reactNode = ReactDOM.render(
                //TODO: Fix this any
                <CircleSpinner color={this._spinnerColor} size={this._spinnerSize} /> as any,
                this._DOMContent
            );


            if (!this._businessLogic.hasDataLoaded())  // when opened first time, load-data automaticly
            {
                this.onClickLoadData(null);
            }
            else {
                this.clearGUI();
                this._businessLogic.processLoadData(false, this._barChartCanvas);
            }

        }
        else {
            if (this._reactNode) {
                ReactDOM.unmountComponentAtNode(this._DOMContent)
                this._reactNode = null
            }

            if (this._viewer && this._viewer.model) {
                this._viewer.clearThemingColors(this._viewer.model);
            }
        }
    }


    //=================================================================================================================


    public showGUIElements(bShow: boolean): void {
        if (bShow) {
            if (this._assembliesTableDiv.style.display === 'none')  // show !!!
                this._assembliesTableDiv.style.display = "block";

            if (this._assemblyIdent.style.display === 'none')
                this._assemblyIdent.style.display = "block";



            if (this._barChartCanvas.style.display === 'none')
                this._barChartCanvas.style.display = "block";

            if (this._barChartWrapper.style.display === 'none')
                this._barChartWrapper.style.display = "block";


            if (this._qrCodeWrapper.style.display === 'none')
                this._qrCodeWrapper.style.display = "block";

        }
        else {
            this._assembliesTableDiv.style.display = "none";
            this._barChartCanvas.style.display = "none";
            this._barChartWrapper.style.display = "none";
            this._assemblyIdent.style.display = "none";
            this._qrCodeWrapper.style.display = "none";
            this._errInfoDiv.style.display = "none";



        }
    }


    //=================================================================================================================


}


















