import {Client, ContentDto, ContentParameter, Library, FileType, Source, ParameterDto, TypeDto} from  '../../../client';
import { HtmlUtils } from './HtmlUtils';
import { ExtensionsError, ExtensionsInfo, ExtensionUtil } from './utils';

export interface StdIdWithNameStructure
{
    id:string,
    name:string
}

export interface ICLibDataExtStruct
{
    Title        :string;
    Filename     :string;
    Name         :string;
    Typename     :string;
    Value        :string;
    RevitYear    :number;
    Source       :string;
    LibraryId           :string;
    CurrentRevisionId   :string;
    RepositoryFileId    :string; 
    NumberInBIMCad      :boolean;
    ProdDesc            :string;
    RSenCProdDesc       :string;
    RSenSNetMass    :number;
    NumberInPortfolio   :boolean;
    NumberInUnifi       :boolean
}

export enum SourceNames
{
    /// <summary>
    /// The source is unknown.
    /// </summary>
    Unknown,

    /// <summary>
    /// The source is Unifi.
    /// </summary>
    Unifi,

    /// <summary>
    /// The source is Revit.
    /// </summary>
    Revit
}



export class UnifiCore
{
    //=================================================================================================================
    public static readonly PRODUCT_DESCRIPTION       = "product description";
    public static readonly RSEN_PRODUCT_DESCRIPTION  = "rsen_c_product_description";
    public static readonly RSEN_NET_MASS = "rsen_s_net_mass";
    public static readonly ITEM_NUMBER               = "item number";
    //=================================================================================================================

    async getLibrariesFromDatabase(apiClient:Client,dbFilterKey:string)
    {

        // get all content-parameters from databse
        await apiClient.getContentParameterDataFromStorage(dbFilterKey).then( (dbContentParams:ContentParameter[]) =>  {

            let databaseLibrariesIds:string[] = [];
           
            for (let item of dbContentParams)
            {
                databaseLibrariesIds.push(item.libraryId);
            }

            // get libraries from Unifi !!!

            apiClient.libraries().then( unifiLibraries =>  {

                let tmp_A_libraries:StdIdWithNameStructure[] = [];
    
                for (let currrentUnifiLib of unifiLibraries)
                {
                    // check if this lib is in database
                    if (databaseLibrariesIds.includes(currrentUnifiLib.id))
                    {
                        const cx:StdIdWithNameStructure = {  id: currrentUnifiLib.id , name: currrentUnifiLib.name   };
                        tmp_A_libraries.push(cx);
                    }
                }
            });    
        });
    }

    //=================================================================================================================

    public static async getAllUnifiLibraries(apiClient:Client,sorted:boolean) : Promise<StdIdWithNameStructure[]> 
    {
        let allLibraries:StdIdWithNameStructure[] = [];

        await apiClient.libraries().then( (libraries:Library[]) =>  {

            for (let item of libraries)
            {
                const cx:StdIdWithNameStructure = {  id: item.id , name: item.name   };
                allLibraries.push(cx);
            }
            if (sorted)
            {
                allLibraries = allLibraries.sort( UnifiCore.compareNames );
            }
        });
        // .catch((error) => { 
       
        //  //   this.showSpinner(false,null);            
        //     let errInfo = ExtensionUtil.getErrorText(ExtensionsError.ERROR_CONNECT_DB_SERVER);
        //     alert( "Error: " + errInfo );  
        //     return null;
       
        // });
        return allLibraries;
    }

    //=================================================================================================================

    public static compareNames(item1: StdIdWithNameStructure, item2: StdIdWithNameStructure) {  
        if (item1.name > item2.name) { return 1; }  
        if (item1.name < item2.name) {return -1; }  
        return 0;  
    } 

   //=================================================================================================================

    public static async getLibraryContentByLibId(apiClient:Client,libId:string,libName:string,isLastLibToLoad:boolean,
                                                 errInfoData:string[],currIndex:number,maxIndex:number,
                                                 allData:ContentDto[],idLabelHtmlElement:string) : Promise<ICLibDataExtStruct[]> 
    {
        console.log("try to load " + libName);

        const libData:ICLibDataExtStruct[]=[];
        
        const infoText = "loading: " + "( " + currIndex + " / " + maxIndex +    "  )  " + libName;

        HtmlUtils.showHtmlElement(  idLabelHtmlElement,  true);
        HtmlUtils.setText(          idLabelHtmlElement,  infoText);
             

        await apiClient.itemnumbers(libId,FileType.RFA).then( (contents:ContentDto[]) =>  {
                

            let uncompleteRecordsCounter = 0;
            let overwrite_item_number = 0;
            let itemNumberParamCounter = 0;
            let overwrite_item_number_Info = "";
            let overwrite_prod_descript_Info = "";
            let overwrite_prod_descript = 0;
            let productDescriptCounter = 0;
            let rsenProductDescriptCounter=0;
            let overwrite_rsen_descript =0;
            let overwrite_rsen_prod_descript_Info="";

            for (let item of contents)
            {
                if (allData != null)
                {
                    allData.push(item);
                }

                //===================================================
                const currItem_familyTypesArray:TypeDto[] = item.familyTypes;
                
            
                //===================================================
                for (let xIndex = 0; xIndex < currItem_familyTypesArray.length; ++xIndex)
                {
                    var parameterList = currItem_familyTypesArray[xIndex].parameters;
                    var typeName      = currItem_familyTypesArray[xIndex].typeName;
                       
                    if (parameterList != null && parameterList.length > 0)                    
                    {
                        let product_description_value = "";
                        let rsen_description_value    = "";
                        let rsen_net_mass_value       = 0;
                        let item_number_value         = "";
                        let typeName                  = "";
                        let revitYear                 = 0;
                        let familyCategory            = "";

                        for (let yIndex = 0; yIndex < parameterList.length; ++yIndex)
                        {
                            if (parameterList.length != 3)
                            {
                               // alert("warn not 3");
                            }

                            let currParam:ParameterDto = parameterList[yIndex];
                                                     
                            typeName       = currParam.typeName;
                            revitYear      = currParam.revitYear;
                            familyCategory = currParam.familyCategory;
                            const currName = currParam.name.toLowerCase();

                            if ( currName.localeCompare(UnifiCore.PRODUCT_DESCRIPTION) == 0 )
                            {
                                if (product_description_value.length > 0)
                                {
                                    if (product_description_value != currParam.value)
                                    {
                                        ++overwrite_prod_descript;
                                        overwrite_prod_descript_Info += "      " +currParam.value + " ==> " +product_description_value + "  " + "\r\n";
                                    }
                                }
                                product_description_value = currParam.value;
                                ++productDescriptCounter;
                            }
                            else if ( currName.localeCompare(UnifiCore.RSEN_PRODUCT_DESCRIPTION) == 0)
                            {
                                if ( rsen_description_value.length > 0)
                                {
                                    if (rsen_description_value != currParam.value)
                                    {
                                        ++overwrite_rsen_descript; 
                                        overwrite_rsen_prod_descript_Info += "      " +currParam.value + " ==> " +rsen_description_value + "  " + "\r\n";
                                    }
                                }
                                rsen_description_value = currParam.value;
                                ++rsenProductDescriptCounter;
                            }                    
                            else if ( currName.localeCompare(UnifiCore.ITEM_NUMBER) == 0)
                            {
                                if (item_number_value.length > 0)              // if we already have set an item-number
                                {
                                    if (item_number_value !=  currParam.value)  // then, check if the number is diffent to the number we already have set
                                    {                                            // if yes, then .. here we have a case of item-number overwriting !!!  
                                        overwrite_item_number_Info += "      " + currParam.value + " ==> " +item_number_value + "\r\n";
                                        ++overwrite_item_number;
                                    }
                                }
                                item_number_value = currParam.value;
                                ++itemNumberParamCounter;
                            }
                            else if ( currName.localeCompare(UnifiCore.RSEN_NET_MASS) == 0)
                            {
                                rsen_net_mass_value = parseFloat(currParam.value);
                            }
                        }    

                        if (item_number_value.length == 0  || rsen_description_value.length == 0 || product_description_value.length == 0)
                        {
                            if (item_number_value.length == 0)
                                item_number_value="0";

                            ++uncompleteRecordsCounter;
                        }

                        const cxx:ICLibDataExtStruct = {  

                            Title:             item.title,  
                            Filename:          item.filename,
                            LibraryId:         item.libraryId,   
                            CurrentRevisionId: item.currentRevisionId,
                            RepositoryFileId:  item.repositoryFileId,
                            Name:              "", 
                            Typename:          typeName, 
                            ProdDesc:          product_description_value,
                            RSenCProdDesc:     rsen_description_value,
                            RSenSNetMass:      rsen_net_mass_value,
                            Value:             item_number_value, 
                            RevitYear:         revitYear, 
                            Source:            SourceNames[ item.source ], 
                            NumberInUnifi:     false,
                            NumberInBIMCad:    false,
                            NumberInPortfolio: false };    

                        libData.push(cxx);  

                        product_description_value = "";
                        rsen_description_value    = "";
                        item_number_value         = "";
                    }
                }
            }


            console.log("successful load of  " + libName);

            let inconsitentData = "";

            if (overwrite_item_number > 0)
            {
                inconsitentData += "item-number" + "\n\r";
                inconsitentData += overwrite_item_number_Info;
            }
            if (overwrite_prod_descript > 0)
            {
                inconsitentData += "product-description-overwrite" + "\n\r";
                inconsitentData += overwrite_prod_descript_Info;
            }   
            if (overwrite_rsen_descript > 0)
            {
                inconsitentData += "rsen-product-description-overwrite" + "\n\r";
                inconsitentData += overwrite_rsen_prod_descript_Info;                            
            }
            if (uncompleteRecordsCounter > 0)
            {
                inconsitentData += "\r\n" + uncompleteRecordsCounter.toString() + " uncomplete Records have been detected !"
            }                        
        })
        .catch((error) => { 
            const errInfo = "[Error]: name: " + libName + "  id: " + libId + "  " + ExtensionUtil.getErrorText(ExtensionsError.ERROR_READ_UNIFI_LIB);    
            console.log( errInfo );
            errInfoData.push( errInfo);
            UnifiCore.saveErrorInfoToFile(errInfo,libName);
            return null;
        });
        return( libData );
   }

   //==================================================================================================================



   private static saveErrorInfoToFile(errInfo:string,libName:string)
   {
       const now_as_number  = Date.now();
       const uniquefileName =  now_as_number +  "-" + libName + "_unifi_errinfo.err";

       HtmlUtils.saveTextFile(uniquefileName,errInfo);
   }

   //=================================================================================================================

   public static async getSelectedLibraries( apiClient:Client, selectedLibs:StdIdWithNameStructure[],errorInfoArray:string[],
                                             startIndex:number,gridData:ICLibDataExtStruct[], allData:ContentDto[], idLabelHtmlElement:string)
   {
       for( let index = startIndex; index < selectedLibs.length; ++index)
       {
           const curselOption    = selectedLibs[index];
           const isLastLibToLoad = ( (index) == selectedLibs.length-1) ? true : false;

           await UnifiCore.getLibraryContentByLibId(apiClient,curselOption.id,curselOption.name,isLastLibToLoad,errorInfoArray,
                                                    index+1,selectedLibs.length,allData,idLabelHtmlElement).then( (libData:ICLibDataExtStruct[]) =>  {

                if (libData !== null)
                {
                    for (let item of libData)    //  gridData = [...libData]; does NOT work here 
                    {
                        gridData.push(item);
                    }
                }
           });                 
       }
   }
 
   //=================================================================================================================

}
